import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FoodOrder from "../admins/foods/order_food";
import roomImg from "../../../images/room.jpg";

const cardStyles = {
  backgroundColor: "#ffffff",
  width: "17.5rem",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  overflow: "hidden",
  border: "1px solid #ddd",
  margin: "18px",
  transition: "box-shadow 0.3s ease, transform 0.3s ease",
};

const imgContainerStyles = {
  overflow: "hidden",
  transition: "transform 0.3s ease",
  position: "relative",
};

const imgStyles = {
  width: "100%",
  height: "100px",
  objectFit: "cover",
};

const cardBodyStyles = {
  padding: "8px",
};

const cardTitleStyles = {
  fontSize: "1.15rem",
  margin: "0",
  fontWeight: "bold",
};

const priceContainerStyles = {
  marginBottom: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "1.1rem",
};

const discountedPriceStyles = {
  color: "#000000",
  fontWeight: "600",
};

const cardTextStyles = {
  fontSize: "0.9rem",
  margin: "0",
  color: "#555",
};

const buttonStyles = {
  backgroundColor: "#007bff",
  border: "none",
  color: "#fff",
  padding: "6px 16px",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: "600",
};

const checkoutButton = {
  backgroundColor: "blueviolet",
  border: "none",
  color: "#fff",
  padding: "6px 16px",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: "600",
};

const editButton = {
  backgroundColor: "#ff1200",
  border: "none",
  color: "#fff",
  padding: "6.5px 8px",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "0.95rem",
  fontWeight: "600",
};

const orderFoodButton = {
  backgroundColor: "#4dd42e",
  border: "none",
  color: "#fff",
  padding: "6px 12px",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: "600",
};

const editIconStyles = {
  position: "absolute",
  top: "10px", // Adjust to move the icon down
  right: "10px", // Adjust to move the icon left
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: semi-transparent background
  color: "white",
  padding: "5px 10px",
  borderRadius: "50%",
  cursor: "pointer",
};

function RoomCard({ roomData, handleToast, id }) {
  const [bookingDetails, setBookingDetails] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  // const [showModal, setShowModal] = useState(false);

  // Fetch booking details if room is booked
  useEffect(() => {
    if (roomData.booking_id) {
      axios
        .get(
          `https://api.dectwinhotel.com/api/${id}_bookings/bookings/${roomData.booking_id}`
        )
        .then((response) => {
          setBookingDetails(response.data[0]); // Store booking details
        })
        .catch((error) => {
          console.error("Error fetching booking details:", error);
        });
    }
  }, [roomData.booking_id, id]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // function toggleModal() {
  //   if (showModal) {
  //     onDeleteRoom();
  //   }
  //   setShowModal(!showModal);
  // }

  // async function onDeleteRoom() {
  //   await axios.delete(
  //     `https://api.dectwinhotel.com/api/${id}_rooms/delete-room/${roomData.room_id}`
  //     // `https://api.dectwinhotel.com/api/${id}_foods/delete-room/${roomData.room_id}`
  //   );
  //   localStorage.removeItem("rooms");
  //   // toggleModal();
  //   window.location.reload();
  // }

  return (
    <div style={cardStyles} className="card-hover">
      <div className="img-container" style={imgContainerStyles}>
        <div>
          <img src={roomImg} alt="Card top" style={imgStyles} />
        </div>
        <Link
          to={bookingDetails ? "/admin/edit-booking" : "/admin/add-room"}
          state={bookingDetails ?? roomData}
        >
          <span
            title={bookingDetails ? "Edit Booking" : "Edit Room"}
            style={editIconStyles}
          >
            <i className="fas fa-edit"></i>
          </span>
        </Link>
      </div>
      {roomData.availability_status ? (
        <div style={cardBodyStyles}>
          <Link to="/admin/book-room" state={roomData}>
            <h5 style={cardTitleStyles}>Room No {roomData.room_number}</h5>
          </Link>

          <p style={cardTextStyles}>Bed Type: {roomData.bed_type}</p>
          <p style={cardTextStyles}>Room Type: {roomData.room_type}</p>

          {/* Price Section */}
          <div style={priceContainerStyles}>
            <span style={discountedPriceStyles}>Price: ₹{roomData.price}</span>
          </div>

          <div
            className="rooms-data"
            style={{ justifyContent: "space-between" }}
          >
            <Link to="/admin/book-room" state={roomData}>
              <button style={buttonStyles}>Check In</button>
            </Link>
            {/* <button style={editButton} onClick={toggleModal}>
              {showModal ? "Sure Delete?" : "Delete Room"}
            </button> */}
            {/* <Link to="/admin/book-advance" state={roomData}>
              <button style={editButton}>Advance Booking</button>
            </Link> */}
            <Link to="/admin/add-room" state={roomData}>
              <button style={editButton}> Edit Room </button>
            </Link>
          </div>
        </div>
      ) : (
        <div style={cardBodyStyles}>
          <h5 style={cardTitleStyles}>
            Room No {roomData.room_number} :{" "}
            <span style={{ color: "#ff0000" }}>Booked</span>
          </h5>

          {bookingDetails ? (
            <>
              <p style={cardTextStyles}>
                Guest: {JSON.parse(bookingDetails.guest_ids).name[0]}
              </p>
              {/* <p style={cardTextStyles}>
                Phone: {bookingDetails.phone}
              </p> */}
              <p style={cardTextStyles}>From: {bookingDetails.from_date}</p>
              <p style={cardTextStyles}>To: {bookingDetails.to_date}</p>
            </>
          ) : (
            <p style={cardTextStyles}>Loading booking details...</p>
          )}

          {/* Price Section */}
          <div style={priceContainerStyles}>
            <span style={discountedPriceStyles}>
              Paid: ₹
              {bookingDetails
                ? bookingDetails.advance_amount
                : roomData.total_price}
            </span>
          </div>

          <div
            className="rooms-data"
            style={{ justifyContent: "space-between" }}
          >
            <Link to="/admin/leave-room" state={bookingDetails}>
              <button style={checkoutButton}>Check Out</button>
            </Link>
            <button onClick={handleOpenModal} style={orderFoodButton}>
              Order Food
            </button>
          </div>
        </div>
      )}
      <FoodOrder
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        bookingId={roomData.booking_id}
        handleToast={handleToast}
        id={id}
      />
    </div>
  );
}

export default RoomCard;
