import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../styles/admins/invoices/guest.css";

// Modal component for adding a new guest
const GuestModal = ({
  onClose,
  addGuestToForm,
  handleToast,
  id,
  closeLoading,
  handleLoading,
}) => {
  const [guestData, setGuestData] = useState({
    id: "",
    name: "",
    guest_name: "",
    contact: "",
    comp_contact: "",
    address: "",
    is_com: false,
    identity_proof_document: "",
  });
  const [guests, setGuests] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [haveToAdd, setHaveToAdd] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [switchDocId, setSwitchDocId] = useState(true);

  const toggleCompanyMode = () => {
    setGuestData({ ...guestData, is_com: !guestData.is_com });
  };

  const toggleDocMode = () => {
    setSwitchDocId((switchDocId) => !switchDocId);
  };

  useEffect(() => {
    // localStorage.removeItem("guests");
    // Check if guests are already in localStorage
    const storedGuests = localStorage.getItem("guests");

    if (storedGuests) {
      // Use cached data if available
      setGuests(JSON.parse(storedGuests));
    } else {
      // Fetch guests from the API
      axios
        .get(`https://api.dectwinhotel.com/api/${id}_guests/guests`)
        .then((response) => {
          setGuests(response.data);
          // Store guests in localStorage
          localStorage.setItem("guests", JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching guests:", error);
        });
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setGuestData({
      ...guestData,
      [name]: files ? files[0] : value,
    });
  };

  // Handle input change in the guest name field and fetch suggestions
  const handleNameChange = (e) => {
    const value = e.target.value;
    setGuestData({ ...guestData, name: value });

    if (value.length > 0) {
      // Filter guests based on input
      const filteredSuggestions = guests.filter(
        (guest) =>
          guest.name.toLowerCase().includes(value.toLowerCase()) ||
          guest.contact.includes(value)
      );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (guest) => {
    // When user clicks a suggestion, autofill the guest data
    var identity = "";
    var guestName = "";
    const Gname = guest.name.split(",");

    if (guest.identity_proof) {
      identity = guest.identity_proof.includes("uploads/")
        ? guest.identity_proof.split("uploads/")[1]
        : guest.identity_proof;
    }
    if (guest.is_com && Gname.length > 1) {
      guestName = Gname[1];
      for (let i = 2; i < Gname.length; i++) {
        guestName = guestName + "," + Gname[i];
      }
    }

    setGuestData({
      id: guest.id,
      name: Gname[0],
      guest_name: guestName,
      contact: guest.contact,
      comp_contact: guest.comp_contact ?? "",
      address: guest.address,
      is_com: guest.is_com,
      identity_proof_document: identity,
    });
    setHaveToAdd(true);
    setShowSuggestions(false);
  };

  const handleAddGuest = async () => {
    handleLoading();

    if (guestData.is_com) {
      guestData.name = guestData.name.replaceAll(",", " ");
      guestData.name = guestData.name + "," + guestData.guest_name;
    }
    try {
      // Simple validation
      if (!guestData.name || !guestData.contact) {
        handleToast({
          toastMsg:
            "Something went wrong! Please check all fields should be filled.",
          toastColor: "#FF0000",
        });
        closeLoading();
        return;
      }

      if (haveToAdd) {
        addGuestToForm(guestData);
        const index = guests.findIndex((item) => item.id === guestData.id);
        if (index !== -1) {
          guests[index] = { ...guests[index], ...guestData };
        }
        localStorage.removeItem("guests");
        localStorage.setItem("guests", JSON.stringify(guests));
        handleToast({
          toastMsg: "Guest Added Successfully!!!",
          toastColor: "#4CAF50",
        });
        closeLoading();
        onClose();
        return;
      }

      // Add guest to the database
      const response = await axios.post(
        `https://api.dectwinhotel.com/api/${id}_guests/add-guest`,
        guestData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      closeLoading();
      if (response.status === 200) {
        const newGuest = response.data;

        setGuests([...guests, newGuest]);
        localStorage.setItem("guests", JSON.stringify([...guests, newGuest]));
        addGuestToForm(newGuest);
        handleToast({
          toastMsg: "Guest Added Successfully!!!",
          toastColor: "#4CAF50",
        });
        onClose();
      }
    } catch (err) {
      closeLoading();
      handleToast({
        toastMsg:
          "Something went wrong! Please check your internet connection.",
        toastColor: "#FF0000",
      });
    }
  };

  return (
    <div className="modal" onDoubleClick={() => setShowSuggestions(false)}>
      <div className="modal-content">
        <div className="d-flex justify-content-between">
          <h2>{guestData.is_com ? "Add Company" : "Add Guest"}</h2>
          <label className="switch" title="Switch To Company Mode">
            <input
              type="checkbox"
              checked={guestData.is_com}
              value={guestData.is_com}
              onChange={() => {
                toggleCompanyMode();
              }}
            />
            <span className="slider"></span>
          </label>
        </div>
        {guestData.is_com ? (
          <>
            <label htmlFor="name">Company Name</label>
            <input
              type="text"
              name="name"
              maxLength={150}
              placeholder="Company Name"
              value={guestData.name}
              onChange={handleNameChange}
              required
            />
            <label htmlFor="name">Guest Name</label>
            <input
              type="text"
              name="guest_name"
              maxLength={100}
              placeholder="Guest Name"
              value={guestData.guest_name}
              onChange={handleChange}
            />
            <label htmlFor="name">Guest Contact</label>
            <input
              type="text"
              name="comp_contact"
              maxLength={22}
              placeholder="Guest Contact"
              value={guestData.comp_contact}
              onChange={handleChange}
            />
          </>
        ) : (
          <>
            <label htmlFor="name">Guest Name</label>
            <input
              type="text"
              name="name"
              maxLength={80}
              placeholder="Guest Name"
              value={guestData.name}
              onChange={handleNameChange}
            />
          </>
        )}

        {/* Suggestions dropdown */}
        {showSuggestions && suggestions.length > 0 && (
          <ul className="suggestions-list">
            {suggestions.map((guest) => (
              <li
                key={guest.id}
                onClick={() => handleSuggestionClick(guest)}
                className="suggestion-item"
              >
                {guest.name} - {guest.contact}
              </li>
            ))}
          </ul>
        )}

        <label htmlFor="contact">
          {guestData.is_com ? "Company GSTIN" : "Guest Contact"}
        </label>
        <input
          type="text"
          name="contact"
          maxLength={16}
          placeholder={guestData.is_com ? "Company GSTIN" : "Guest Contact"}
          value={guestData.contact}
          onChange={handleChange}
          required
        />

        <label htmlFor="address">
          {guestData.is_com ? "Company Address" : "Guest Address"}
        </label>
        <input
          type="text"
          name="address"
          placeholder={guestData.is_com ? "Company Address" : "Guest Address"}
          value={guestData.address}
          onChange={handleChange}
        />

        <div className="d-flex justify-content-between">
          <label htmlFor="identity_proof_document">Identity Proof</label>
          <label className="switch" title="Document Id Number">
            <input
              type="checkbox"
              checked={switchDocId}
              value={switchDocId}
              onChange={() => {
                toggleDocMode();
              }}
            />
            <span className="slider"></span>
          </label>
        </div>
        {haveToAdd || switchDocId ? (
          <input
            type="text"
            name="identity_proof_document"
            placeholder="Identity Proof Number"
            value={guestData.identity_proof_document}
            onChange={handleChange}
          />
        ) : (
          <input
            type="file"
            name="identity_proof_document"
            accept="image/*,application/pdf"
            onChange={handleChange}
          />
        )}

        <button onClick={handleAddGuest}>Add Guest</button>
        <br />
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default GuestModal;
