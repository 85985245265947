import React from "react";
import { Link } from "react-router-dom";

const cardStyles = {
  backgroundColor: "#ffffff",
  width: "19rem",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  overflow: "hidden",
  border: "1px solid #ddd",
  margin: "20px",
  transition: "box-shadow 0.3s ease, transform 0.3s ease",
};

const cardBodyStyles = {
  padding: "15px",
};

const cardTitleStyles = {
  fontSize: "1.25rem",
  margin: "0",
  fontWeight: "bold",
};

const priceContainerStyles = {
  marginBottom: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "1.1rem",
};

const discountedPriceStyles = {
  color: "#000000",
  fontWeight: "600",
};

const cardTextStyles = {
  fontSize: "1rem",
  margin: "0",
  color: "#555",
};

const buttonStyles = {
  backgroundColor: "#007bff",
  border: "none",
  color: "#fff",
  padding: "6px 16px",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: "600",
};

const editButton = {
  backgroundColor: "#008800",
  border: "none",
  color: "#fff",
  padding: "6.5px 8px",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "0.95rem",
  fontWeight: "600",
};

function AdvanceRoomCard({
  roomData,
  handleCheckIn,
  handleCancel,
  bookingData,
  today,
}) {
  // Check if any booking falls within today's date
  function checkBookingDate(bookings) {
    const books = bookings.find((booking) => {
      const fromDate = new Date(booking.from_date);
      const toDate = new Date(booking.to_date);
      today.setHours(0, 0, 0, 0);
      fromDate.setHours(0, 0, 0, 0);
      toDate.setHours(0, 0, 0, 0);
      return today >= fromDate && today <= toDate;
    });
    return books ?? {};
  }
  const bookingDataofDay =
    bookingData.length > 0 ? checkBookingDate(bookingData) : {};

  return (
    <div style={cardStyles} className="card-hover">
      <div style={cardBodyStyles}>
        <h5 style={cardTitleStyles}>Room No: {roomData.room_number}</h5>

        {Object.keys(bookingDataofDay).length > 0 ? (
          <>
            <p style={cardTextStyles}>
              Boking ID: ADV{bookingDataofDay.advance_booking_id}
            </p>
            <p style={cardTextStyles}>
              Guest: {JSON.parse(bookingDataofDay.guest_ids).name[0]}
            </p>
            <p style={cardTextStyles}>From: {bookingDataofDay.from_date}</p>
            <p style={cardTextStyles}>To: {bookingDataofDay.to_date}</p>

            {/* Price Section */}
            <div style={priceContainerStyles}>
              <span style={discountedPriceStyles}>
                Paid Amount: ₹ {bookingDataofDay.advance_amount}
              </span>
            </div>
            <div className="rooms-data" style={{ justifyContent: "center" }}>
              {bookingDataofDay.booking_status === "pending" ? (
                <>
                  <button
                    style={buttonStyles}
                    onClick={() => {
                      handleCheckIn(bookingDataofDay);
                    }}
                  >
                    Checkin
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <button
                    style={{ ...editButton, backgroundColor: "#FF0000" }}
                    onClick={() => {
                      handleCancel(bookingDataofDay);
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;Cancel&nbsp;&nbsp;&nbsp;
                  </button>
                </>
              ) : (
                <button
                  style={{
                    ...editButton,
                    backgroundColor: "#ffffff",
                    color: "black",
                    border: "1px solid black",
                  }}
                >
                  {bookingDataofDay.booking_status.toUpperCase()}
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            <p style={cardTextStyles}>Boking ID: Not Booked</p>
            <p style={cardTextStyles}>Guest: Not Booked</p>
            <p style={cardTextStyles}>From: Not Booked</p>
            <p style={cardTextStyles}>To: Not Booked</p>

            {/* Price Section */}
            <div style={priceContainerStyles}>
              <span style={discountedPriceStyles}>
                Paid Amount: ₹ Not Booked
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link to="/admin/book-advance" state={roomData}>
                <button style={editButton}>Book in Advance</button>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AdvanceRoomCard;
