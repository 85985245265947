import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../styles/admins/rooms/adv_book_history.css";
import AdvanceRoomCard from "../../generals/adv_room_card";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

const AdvanceBookingHistory = (props) => {
  const [bookings, setBookings] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();

  // Fetch booking details if room is booked in advance
  useEffect(() => {
    const storedRooms = localStorage.getItem("rooms");
    axios
      .get(
        `https://api.dectwinhotel.com/api/${props.id}_advance_bookings/advance-history`
      )
      .then((response) => {
        setBookings(response.data);
      })
      .catch((error) => {
        console.error("Error fetching booking details:", error);
      });
    if (storedRooms) {
      // Use cached data if available
      setRooms(JSON.parse(storedRooms));
    } else {
      // Fetch products from the API
      axios
        .get(`https://api.dectwinhotel.com/api/${props.id}_rooms/rooms`)
        .then((response) => {
          // Store products in localStorage
          localStorage.setItem("rooms", JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    }
  }, [props.id]);

  // Handle check-in creation for a specific booking
  const handleCancel = async (booking) => {
    props.handleLoading();
    await axios.put(
      `https://api.dectwinhotel.com/api/${props.id}_advance_bookings/cancel-booking/${booking.advance_booking_id}`
    );
    navigate("/admin/dashboard");
    props.closeLoading();
  };

  const handleCheckIn = async (booking) => {
    props.handleLoading();
    try {
      booking.live_image = null;
      booking.live_image_file = null;
      const response = await axios.post(
        `https://api.dectwinhotel.com/api/${props.id}_bookings/checkin-room`,
        booking,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      props.closeLoading();
      if (response.status === 201) {
        props.handleToast({
          toastMsg: "Check In Successfully!!!",
          toastColor: "#4CAF50",
        });
        await axios.put(
          `https://api.dectwinhotel.com/api/${props.id}_advance_bookings/success-booking/${booking.advance_booking_id}`
        );
        navigate("/admin/dashboard");
        localStorage.removeItem("rooms");
        // navigate("/admin/rooms");
      } else {
        props.handleToast({
          toastMsg: "Something went wrong! Please check data or internet.",
          toastColor: "#FF0000",
        });
      }
    } catch (err) {
      props.closeLoading();
    }
  };

  return (
    <section
      style={{
        backgroundColor: "#282c34",
        padding: "1rem",
        minHeight: "90vh",
      }}
    >
      <div className="advance-bookings-section">
        <h2>Advance Booking History</h2>{" "}
        <div className="date-picker-adv">
          Pick A Date : &nbsp;
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </div>
        <div className="advance-bookings-cards">
          {rooms.length > 0 ? (
            rooms.map((room) => (
              <AdvanceRoomCard
                key={room.room_id}
                id={props.id}
                roomData={{ ...room, booking_id: bookings.length + 1 }}
                bookingData={bookings.filter((elm) => {
                  return elm.room_id === room.room_id;
                })}
                handleCancel={handleCancel}
                handleCheckIn={handleCheckIn}
                today={startDate}
              />
            ))
          ) : (
            <div className="no-rooms-message">
              <i
                style={{
                  fontSize: "6rem",
                  margin: "30px 10px",
                  color: "black",
                }}
                className="fa fa-bed"
              ></i>
              <p>No Advance Bookings done yet.</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AdvanceBookingHistory;
