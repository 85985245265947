import React, { useState } from "react";
import axios from "axios";
import "../../../styles/admins/foods/add_food.css";
import { useLocation } from "react-router-dom";

const AddFood = (props) => {
  const location = useLocation();
  const [foodData, setFoodData] = useState({
    food_name: location.state !== null ? location.state.food_name : "",
    food_category: location.state !== null ? location.state.category : "",
    price: location.state !== null ? location.state.price : "",
    food_description:
      location.state !== null ? location.state.food_description : "",
  });

  const handleChange = (e) => {
    setFoodData({ ...foodData, [e.target.name]: e.target.value });
  };

  // Handle change in number input
  const handleNumberChange = (e) => {
    const value = e.target.value;

    if (value.length <= 7 && /^[0-9]*$/.test(value)) {
      setFoodData({ ...foodData, [e.target.name]: e.target.value });
    } else {
      props.handleToast({
        toastMsg: "Invalid Contact! Please enter a valid mobile number",
        toastColor: "#FF0000",
      });
      setFoodData({ ...foodData, [e.target.name]: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.handleLoading();
    try {
      await axios.post(
        `https://api.dectwinhotel.com/api/${props.id}_foods/add-foods`,
        foodData
      );
      // Reset form
      setFoodData({
        food_name: "",
        food_category: "",
        price: "",
        food_description: "",
      });
      props.closeLoading();
      localStorage.removeItem("foods");
      props.handleToast({
        toastMsg: "Food Added Successfully!!!",
        toastColor: "#4CAF50",
      });
    } catch (error) {
      props.closeLoading();
      props.handleToast({
        toastMsg: "Something went wrong! Please check input data or internet.",
        toastColor: "#FF0000",
      });
    }
  };

  return (
    <section style={{ padding: "4rem 1rem" }}>
      <div className="add-food-container">
        <form className="add-food-form" onSubmit={handleSubmit}>
          <h2 className="form-title">
            {location.state ? "Edit Food Item" : "Add Food Item"}
          </h2>

          <div className="form-group">
            <label>Food Name:</label>
            <input
              type="text"
              name="food_name"
              value={foodData.food_name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Food Category:</label>
            <input
              type="text"
              name="food_category"
              value={foodData.food_category}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Food Price:</label>
            <input
              type="text"
              maxLength={7}
              name="price"
              value={foodData.price}
              onChange={handleNumberChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Food Description:</label>
            <textarea
              name="food_description"
              value={foodData.food_description}
              onChange={handleChange}
            />
          </div>

          <button type="submit" className="submit-btn">
            {location.state ? "Edit Food" : "Add Food"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AddFood;
